import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BaseHttpService } from './base-http-service';
import { ShortRegisterData, UserData } from '../models/user-data.model';
import { AuthRegisterResponse } from '../models/auth-register-response.model';
import {
  AuthLoginResponse,
  AuthRefreshResponse,
} from '../models/auth-login-response.model';
import jwtDecode from 'jwt-decode';

/** @todo: Temp interface and implementation, will be updated and moved to future auth module */
export interface AuthTokenResponse {
  token: string;
}

/** @todo: Temp interface and implementation, will be updated and moved to future auth module */
export interface LoggedUser {
  email: string;
  firstName: string;
  lastName: string;
}

export interface JWT {
  email: string;
  firstName?: string;
  lastName?: string;
  role?: string;
  iat: number;
  exp: number;
}

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseHttpService {
  login(email: string, password: string): Observable<AuthLoginResponse> {
    return this.http
      .post<AuthLoginResponse>(`${environment.apiURL}auth/login`, {
        email,
        password,
      })
      .pipe(tap(response => this.storeTokenData(response)))
      .pipe(
        tap(() => {
          const redirectBackUrl = this.storageService.getItem('redirectTo');
          if (redirectBackUrl) {
            this.storageService.removeItem('redirectTo');
            location.href = redirectBackUrl;
          }
        })
      );
  }

  refresh(refreshToken: string): Observable<AuthRefreshResponse> {
    return this.http
      .post<AuthRefreshResponse>(`${environment.apiURL}auth/tokens/refresh`, {
        refreshToken,
      })
      .pipe(tap(response => this.storeTokenData(response)));
  }

  register(email: string, password?: string): Observable<AuthRegisterResponse> {
    return this.http
      .post<AuthRegisterResponse>(`${environment.apiURL}auth/register`, {
        email,
        password: password || this.generatePassword(),
      })
      .pipe(tap(response => this.storeTokenData(response)));
  }

  private storeTokenData(response: AuthLoginResponse): void {
    try {
      const decodedToken = jwtDecode(response.tokens.access) as JWT;
      const decodedRefreshToken = jwtDecode(response.tokens.refresh) as JWT;
      this.storageService.setItem('token', response.tokens.access);
      this.storageService.setItem(
        'refresh',
        response.tokens.refresh,
        decodedRefreshToken.exp * 1000
      );
      this.storageService.setItem(
        'email',
        decodedToken.email,
        decodedToken.exp * 1000
      );
    } catch (error) {}
  }

  getLoggedUser(): LoggedUser {
    const token = this.storageService.getItem('token');
    const decodedToken = token ? (jwtDecode(token) as JWT) : {};
    return decodedToken as unknown as LoggedUser;
  }

  createUser(email: string): Observable<AuthTokenResponse> {
    return this.http.post<AuthTokenResponse>(
      `${environment.apiURL}userRegister`,
      { email, password: this.generatePassword() },
      { headers: this.headers }
    );
  }

  private generatePassword(): string {
    let password = '';
    let options: string = 'abcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 10; i++) {
      password += options.charAt(Math.floor(Math.random() * options.length));
    }
    return password;
  }

  getUserData(): Observable<UserData> {
    return this.http.get<UserData>(`${environment.apiURL}users/current`, {
      headers: this.headers,
    });
  }

  getEmailAvailable(email: string): Observable<any> {
    return this.http.get(
      `${environment.apiURL}users/emailavailable?email=${email}`
    );
  }

  updateUserData(userData: UserData): Observable<UserData> {
    return this.http.put<UserData>(
      `${environment.apiURL}users/current`,
      userData,
      {
        headers: this.headers,
      }
    );
  }

  registerUser(userData: UserData | ShortRegisterData): Observable<UserData> {
    if (!userData.password) {
      userData.password = this.generatePassword();
    }

    return this.http.post<UserData>(`${environment.apiURL}users`, userData, {
      headers: this.headers,
    });
  }
}
