import MicroModal from 'micromodal';
import { AfterViewInit, Component, DoCheck, OnInit } from '@angular/core';
import { LoaderService } from '@v2/core/services/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements DoCheck, AfterViewInit, OnInit {
  title = 'Skiyodl';

  isMobileMenuOpened = false;
  isLoading = false;
  isSearchPage = location.pathname.includes('search');

  constructor(private loaderService: LoaderService) {}

  ngOnInit(): void {
    MicroModal.init({ disableScroll: true });
  }

  ngDoCheck(): void {
    this.isMobileMenuOpened =
      document.getElementsByClassName('col-6 info-popular').length > 0 ||
      document.getElementsByClassName('mobileFilterCheck').length > 0;
  }

  ngAfterViewInit(): void {
    this.loaderService.httpProgress().subscribe((status: boolean) => {
      setTimeout(
        () =>
          (this.isLoading =
            status && (location.pathname.includes('v2/') || this.isSearchPage)),
        50
      );
    });
  }
}
